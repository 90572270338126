
div#loader>div{
    width: 100%;
    min-height: 100vh;
    position: fixed;
    background: var(--Inicia-gradient-color-horizontal);
    background-attachment: fixed;
    display: none;
    z-index: 6;
    top: 0%;
    left: 0%;
    /* opacity: .7; */
}
@keyframes rotate {
    100% {
    transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    }

    50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
    }

    100% {
    stroke-dashoffset: -125px;
    }
}

.modal-loader{
    margin: auto;
    width: 10vw;
    height: 10vw;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.8em;
    position: relative;
}
    .loader{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
        .loader>svg{
            width: 50%;
            height: 50%;
            transform-origin: center;
            animation: rotate 2s linear infinite;
        }
            .loader>svg>circle {
                fill: none;
                stroke: var(--main-green-hex);
                stroke-width: .2em;
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
                stroke-linecap: round;
                animation: dash 1.5s ease-in-out infinite;
            }