.table{
    width: 100%;
    height: 100%;
    font-family: var(--fontfamily-light-set);
}

.table>.table-head{
    width: 100%;
    position: relative;
}
.table>.table-head2{
    grid-template-columns:15% 64% 19% 2% !important;
}
.table>.table-head3{
    grid-template-columns:50% 35% 15% !important;
}
    
    .table>.table-head>.table-head-cell,.table>.table-body>.table-body-row>.table-body-cell{
        max-width: 100%;
        justify-self: center;
        display: block;
        align-self: center;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-align: center;
    }
    .table>.table-head>.table-head-cell,.table>.table-body>.table-body-row>.table-body-cell2{
        max-width: 100%;
        justify-self: center;
        display: block;
        align-self: center;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-align: center;
    }
        /* .table>.table-head>.table-head-cell,.table>.table-body>.table-body-row>.table-body-cell>p:hover::before {
            display: block;
        }
        .table>.table-head>.table-head-cell,.table>.table-body>.table-body-row>.table-body-cell>p::before {
            content: attr(data-text);
            position: absolute;
            display: none;
            top: -100%;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            z-index: 1000000;
          } */
    .table>.table-body{
        width: 100%;
    }
        .table>.table-body>p{
            font-family: var(--fontfamily-medium-set);
            color: var(--dark-color-hex);
            width: fit-content;
            height: fit-content;
        }
@media only screen and (min-width:601px){
    .table{
        font-size: .7em;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 10% 90%;
    }
        .table>.table-head{
            /* --columns:5; */
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
            height: 100%;
            display: grid;
            grid-template-rows: 100%;
            /* grid-template-columns:repeat(var(--columns),1fr) ; */
            border-bottom: .1em;
            border-color: var(--light-color-hex);
            border-style: solid;
        }
            
        .table>.table-body{
            /* --columns:5; */
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 2;
            height: 100%;
            display: block;
            overflow: auto;
        }
            .table>.table-body>p{font-size: 1.2em;}
            .table>.table-body>.table-body-row{
                width: 100%;
                height: fit-content;
                display: grid;
                grid-template-rows: 100%;
                /* grid-template-columns:repeat(var(--columns),1fr); */
                /* margin-top: 1em;
                margin-bottom: 1em; */
                padding-top: .75em;
                padding-bottom: .75em;
            }
            .table>.table-body>.table-body-row2{
                grid-template-columns:15% 65% 20% !important;
            }
            .table>.table-body>.table-body-row3{
                grid-template-columns:50% 35% 15% !important;
            }
        
}
@media only screen and (min-width:768px) and (orientation:portrait){

    .table{
        grid-template-rows: minmax(min-content,4%) 96%;
    }
}
@media only screen and (max-width:600px){
    .table{
        font-size: .8em;
        display: flex;
    }

        .table>.table-head{
            display: none;
        }
        .table>.table-body{
            height: 100%;
            display: block;
            overflow: auto;
        }
            .table>.table-body>p{font-size: 1em;}
            .table>.table-body>.table-body-row{
                margin-left: auto;
                margin-right: auto;
                width: 90%;
                height: fit-content;
                display: flex;
                justify-content: space-evenly;
                padding-top: .7em;
                padding-bottom: .7em;
                
            }
            .table>.table-body>.table-body-row:not(:last-child){
                border-bottom: .1em;
                border-color: var(--light-color-hex);
                border-style: solid;
            }
                .table>.table-body>.table-body-row>.table-body-cell:nth-child(1),.table>.table-body>.table-body-row>.table-body-cell:last-child{
                    max-width: 50%;
                    min-width: 45%;
                    text-align: center;
                }
                .table>.table-body>.table-body-row>.table-body-cell:not(:nth-child(1),:last-child){
                    display: none;
                }
        /* .table>thead{
            display: none;
        }
        .table>tbody{
            display: block;
            width: 100%;
        }
            .table>tbody>tr{
                margin-top: .6em;
                margin-bottom: .6em;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
                .table>tbody>tr>td:nth-child(1),table.table>tbody>tr>td:last-child{
                    margin-left: .5em;
                    margin-right: .5em;
                }
                .table>tbody>tr>td:not(:nth-child(1),:last-child){
                    display: none;
                } */
}