.client-detail-grid{
    align-self: center;
    justify-self: center;
    display: grid;
} 

    .client-detail-grid>.client-info-section,.client-detail-grid>.client-feedback-section,.client-detail-grid>.client-summary-section,.client-detail-grid>.actions-section{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
        .client-detail-grid>a{
            color: var(--main-green-hex);
            font-family: var(--fontfamily-regular-set);
            display: flex;
            align-items: center;
        }
            .client-detail-grid>a>span{
                display: inline-block;
                vertical-align: middle;
            }
                .client-detail-grid>a>span>img{
                    width: 100%;
                    height: 100%;
                    transform: rotate(180deg);
                }

        .client-detail-grid>.client-info-section>p,.client-detail-grid>.client-feedback-section>p,.client-detail-grid>.client-summary-section>p{
            align-self: center;
            width: 90%;
            min-height: fit-content;
        }
        .client-detail-grid>.client-info-section>div,.client-detail-grid>.client-feedback-section>div,.client-detail-grid>.client-summary-section>div{
            align-self: center;
            border-radius: .8rem;
            display: flex;
        }  
        .client-detail-grid>.client-info-section>div,.client-detail-grid>.client-feedback-section>div{
            position: relative;
        } 
            /* Client info */
            .client-detail-grid>.client-info-section>div>form{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .client-detail-grid>.client-info-section>div>button{
                background-image: url(../images/inicia/edit-client-green.svg);
                background-size:contain;
                background-position: center;
                background-repeat: no-repeat;
            }

                .client-detail-grid>.client-info-section>div>form>span{
                    width: 90%;
                    height: fit-content;
                    display: flex;
                }
                    .client-detail-grid>.client-info-section>div>form>span>input{
                        font-family: var(--fontfamily-light-set);
                        margin-left: .1em;
                        margin-right: .1em;
                        width: 100%;
                        text-indent: .4em;
                    }
                    .client-detail-grid>.client-info-section>div>form>span>img{
                        display: none;
                    }

                    .client-detail-grid>.client-info-section>div>form>span>label{
                        font-family: var(--fontfamily-regular-set);
                        width: fit-content;
                        min-width: fit-content;
                    }
            /* Client Feedback */
            .client-detail-grid>.client-feedback-section>div>ol{
                width: 100%;
                height: 95%;
                display: flex;
                flex-direction: column;
                font-family: var(--fontfamily-regular-set);
                overflow: auto;
                align-self: center;
            } 
                .client-detail-grid>.client-feedback-section>div>ol>li{
                    margin-top: 0.6em;
                    margin-bottom: 0.6em;
                    margin-left: 0.9em;
                    margin-right: 0.9em;
                    width: 90%;
                    list-style-type: decimal;
                    list-style-position: inside;
                }
            .client-detail-grid>.client-feedback-section>div>button{
                background-image: url(../images/inicia/trash-client-feedback-dark-hollow.svg);
                background-size:contain;
                background-position: center;
                background-repeat: no-repeat;
            }
            /* Client Actions */
            .client-detail-grid>.actions-section>div{
                margin: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
            }
                .client-detail-grid>.actions-section>div>button,.client-detail-grid>.actions-section>div>a{
                    color: white;
                    font-family: var(--fontfamily-regular-set);
                    border-radius: 5em;
                    height: fit-content;
                    padding-top: 1em;
                    padding-bottom: 1em;
                    text-align: center;
                }

            /* Client Summary */
            .client-detail-grid>.client-summary-section>div{
                margin: auto;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
            }
                .client-detail-grid>.client-summary-section>div>div:nth-child(1){
                    width: 95%;
                    height: fit-content;
                    margin-top: .2em;
                    margin-bottom: .2em;
                    display: flex;
                    justify-content: flex-start;
                }
                    .client-detail-grid>.client-summary-section>div>div:nth-child(1)>span{
                        width: fit-content;
                        max-width: 50%;
                        margin: .3em;
                        display: flex;
                    }
                        .client-detail-grid>.client-summary-section>div>div:nth-child(1)>span>label{
                            font-family:var(--fontfamily-regular-set);
                            margin-right: .1em;
                            margin-left: .1em;
                            
                        }
                        .client-detail-grid>.client-summary-section>div>div:nth-child(1)>span>p{
                            font-family:var(--fontfamily-light-set);
                            text-decoration: underline;
                            margin-right: .1em;
                            margin-left: .1em;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                    .client-detail-grid>.client-summary-section>div>span{
                        width: 95%;
                        margin: .3em;
                        display: flex;
                    }
                        .client-detail-grid>.client-summary-section>div>span>label{
                            font-family:var(--fontfamily-regular-set);
                            margin-right: .1em;
                            margin-left: .1em;
                            
                        }
                        .client-detail-grid>.client-summary-section>div>span>p{
                            font-family:var(--fontfamily-light-set);
                            margin-right: .1em;
                            margin-left: .1em;
                            max-width: 50%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                .client-detail-grid>.client-summary-section>div>div:nth-child(3){
                    width: 95%;
                    height: 70%;
                    margin-top: auto;
                    margin-bottom: auto;
                    align-self: center;
                    display: flex;
                    flex-direction: column;
                }
                    .client-detail-grid>.client-summary-section>div>div:nth-child(3)>p{
                        font-family:var(--fontfamily-regular-set);
                    }
                    .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                        .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>.last-orders-slider-wrapper{
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                        }
                            .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>.last-orders-slider-wrapper>figure{
                                min-width: 45%;
                                width: 45%;
                                margin-left: 2.5%;
                                margin-right: 2.5%;
                                height: 90%;
                                aspect-ratio: 1/1;
                                border-radius: 1.5em;
                                overflow: hidden;
                                transition: 200ms ease;
                            }
                                .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>.last-orders-slider-wrapper>figure>img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit:contain;
                                }
                        .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>button{
                            width: 1em;
                            height: 1em;
                            background-image:url(../images/inicia/arrow-orange.svg) ;
                            background-size:contain;
                            background-position: center;
                            background-repeat: no-repeat;
                        }


@media only screen and (min-width:601px){
    .client-detail-grid{
        min-width: 82%;
        width: 82%;
        height: 95%;
        grid-template-columns: 70% 30%;
        grid-template-rows: 54% 34% 8%;
        grid-gap: 3% 0%;
    }
        .client-detail-grid>a{
            font-size: .8em;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;

        }
            .client-detail-grid>a>span{
                width: 1em;
                height: 1em;
            }
        .client-detail-grid>.client-info-section>p,.client-detail-grid>.client-feedback-section>p,.client-detail-grid>.client-summary-section>p{
            font-size: .85em;
            margin-top:.2em;
            margin-bottom:.2em;
        }
        .client-detail-grid>.client-info-section>div,.client-detail-grid>.client-feedback-section>div,.client-detail-grid>.client-summary-section>div{
            width: 95%;
            height: calc(90% - .4em);
        }
        .client-detail-grid>.actions-section>div{
            width: 95%;
            height:95%;
        }
                .client-detail-grid>.client-info-section>div>form>span{
                    font-size: .75em;
                    margin-top: .5em;
                    margin-bottom: .5em;
                    margin-left: .9em;
                    margin-right: .9em;
                }
            .client-detail-grid>.client-info-section>div>button{
                position: absolute;
                right: 1em;
                top: 1em;
                width: 1.6em;
                height: 1.6em;
                cursor: pointer;
            }
            .client-detail-grid>.client-feedback-section>div>ol{
                font-size: .75em;
            }
            .client-detail-grid>.client-feedback-section>div>button{
                position: absolute;
                right: 1em;
                bottom: 1em;
                width: 1em;
                height: 1em;
                cursor: pointer;
            }
            .client-detail-grid>.actions-section>div>button,.client-detail-grid>.actions-section>div>a{
                font-size: .85em;
                cursor: pointer;
            }

        .client-detail-grid>.client-summary-section>div{
            font-size: .75em;
        }
}
@media only screen and (min-width:768px) and (orientation:portrait){
    .client-detail-grid{
        width:100%;
        height: 100%;
        grid-gap: 0%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
        .client-detail-grid>.client-info-section,.client-detail-grid>.client-feedback-section,.client-detail-grid>.client-summary-section,.client-detail-grid>.actions-section{
            margin-top: 2%;
            margin-bottom: 2%;
            height: fit-content;
        }

                    .client-detail-grid>.client-summary-section>div>div:nth-child(3){
                        width: calc(100% - 1em);
                        height: fit-content;
                        margin-top: 1%;
                        margin-bottom: 1%;
                    }
                            .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>button{
                                width: 1.6em;
                                height: 1.6em;
                            }
            .client-detail-grid>.client-info-section>p,.client-detail-grid>.client-feedback-section>p,.client-detail-grid>.client-summary-section>p{
                font-size: .9em;
                margin-top:.5em;
                margin-bottom:.5em;
            }
            .client-detail-grid>.client-info-section>div,.client-detail-grid>.client-feedback-section>div,.client-detail-grid>.client-summary-section>div{
                width: 95%;
                height: fit-content;
            }
                .client-detail-grid>.client-info-section>div>button {
                    position: absolute;
                    right: 1em;
                    top: 1em;
                    width: 1.6em;
                    height: 1.6em;
                }
                    .client-detail-grid>.client-info-section>div>form>span{
                        font-size: .85em;
                        margin-top: .5em;
                        margin-bottom: .5em;
                        margin-left: 0.6em;
                        margin-right: 0.6em;
                    
                    }
                .client-detail-grid>.client-feedback-section>div>ol{
                    font-size: .85em;
                    height: fit-content;
                    max-height: 30vh;
                }
                    .client-detail-grid>.client-feedback-section>div>ol>li{
                        margin-top: 0.8em;
                        margin-bottom: 0.8em;
                    }
            .client-detail-grid>.actions-section>div{
                width: fit-content;
                height: fit-content;
                flex-direction: row;
                flex-wrap: nowrap;
            }
                .client-detail-grid>.actions-section>div>button,.client-detail-grid>.actions-section>div>a{
                    min-width: fit-content;
                    margin-top: .5em;
                    margin-bottom: .5em;
                    margin-left: .7em!important;
                    margin-right: .7em!important;
                    padding-left: .8em;
                    padding-right: .8em;
                }
        .client-detail-grid>.client-summary-section>div{
            font-size: .85em;
            height: fit-content;
            margin: 0% auto;
        }
            .client-detail-grid>.client-summary-section>div>div:nth-child(1){
                margin-left: .6em;
                margin-right: .6em;
            }
            .client-detail-grid>.client-summary-section>div>span{
                margin-left: 1em;
                margin-right: 1em;
            }
            .client-detail-grid>.client-summary-section>div>div:nth-child(3)>p{
                margin-left: .5em;
                margin-right: .5em;
            }
                    .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>.last-orders-slider-wrapper>figure{
                        min-width: 55%;
                        width: 55%;
                        height: 95%;
                        margin-left: auto;
                        margin-right: auto;
                    }

        
}
@media only screen and (max-width:600px){
    .client-detail-grid{
        width:100%;
        height: 100%;
        grid-template-columns: 100%;
        grid-auto-rows: min-content;
    }
        .client-detail-grid>a{
            font-size: .9em;
            margin-right: 2%;
            grid-row-start: 1;
            grid-row-end: 2;
        }
            .client-detail-grid>a>span{
                width: 1.3em;
                height: 1.3em;
            }
        .client-detail-grid>.client-info-section,.client-detail-grid>.client-feedback-section,.client-detail-grid>.client-summary-section,.client-detail-grid>.actions-section{
            margin-top: 2%;
            margin-bottom: 2%;
        }

            .client-detail-grid>.client-info-section>p,.client-detail-grid>.client-feedback-section>p,.client-detail-grid>.client-summary-section>p{
                font-size: .9em;
                margin-top:.5em;
                margin-bottom:.5em;
            }
            .client-detail-grid>.client-info-section>div,.client-detail-grid>.client-feedback-section>div,.client-detail-grid>.client-summary-section>div{
                width: 95%;
                height: fit-content;
            }
                .client-detail-grid>.client-info-section>div>button {
                    position: absolute;
                    right: 1em;
                    top: 1em;
                    width: 1.6em;
                    height: 1.6em;
                }
                    .client-detail-grid>.client-info-section>div>form>span{
                        font-size: .85em;
                        margin-top: .5em;
                        margin-bottom: .5em;
                        margin-left: 0.6em;
                        margin-right: 0.6em;
                    
                    }
                .client-detail-grid>.client-feedback-section>div>ol{
                    font-size: .85em;
                    height: 30vh;
                    max-height: 30vh;
                }
            .client-detail-grid>.actions-section>div{
                width: 95%;
                height: fit-content;
            }
                .client-detail-grid>.actions-section>div>button,.client-detail-grid>.actions-section>div>a{
                    margin-top: .5em;
                    margin-bottom: .5em;
                }
        .client-detail-grid>.client-summary-section>div{
            font-size: .85em;
            height: 45vh;
        }
            .client-detail-grid>.client-summary-section>div>div:nth-child(1){
                margin-left: .6em;
                margin-right: .6em;
            }
            .client-detail-grid>.client-summary-section>div>span{
                margin-left: 1em;
                margin-right: 1em;
            }
            .client-detail-grid>.client-summary-section>div>div:nth-child(3)>p{
                margin-left: .5em;
                margin-right: .5em;
            }
            .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>button{
                display: none;
            }
                .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>.last-orders-slider-wrapper{
                    overflow: auto;
                }
                    .client-detail-grid>.client-summary-section>div>div:nth-child(3)>.last-orders-slider>.last-orders-slider-wrapper>figure{
                        min-width: 55%;
                        width: 55%;
                        height: 95%;
                        margin-left: 22.5%;
                        margin-right: 22.5%;
                    }

        
}