.filters-section{
    font-family:var(--fontfamily-regular-set) ;
    height: 10%;
}
    .filters-section>input[type="checkbox"]{
        display: none;
    }
   
        .filters-section>div{
            background-color: var(--main-green-hex);
            position: relative;
            height: 100%;
            display: flex;
            justify-content: center;
            color: white;
        }
            
            .filters-section>div>label{
                position: absolute;
                width: 1em;
                height: 1em;
                background-image: url(../images/inicia/filter-arrow-light.svg);
                background-size: contain;
                background-position:center;
                background-repeat: no-repeat;
                transition-property: transform;
                transition-duration: 500ms;
            }
            .filters-section>div>span{
                position: absolute;
                user-select: none;
                display: flex;
                align-items: center;
                opacity: 1;
                transition-property: opacity;
                transition-duration: 200ms;
            }
            .filters-section>div>ul{
                height: fit-content;
                display: flex;
            }  
                .filters-section>div>ul>li{
                    margin-left: 3%;
                    margin-right:3%;
                    position: relative;
                    letter-spacing: .02em;
                }
                .filters-section>div>ul>li>div{
                    position: absolute;
                    top: calc(100% + 0.6em);
                    background-color: white;
                    min-height: 100%;
                    min-width: 100%;
                    border-radius: 0.2rem;
                    z-index: 5;
                }
                .filters-section>div>ul>li>div.active-tooltip{
                    display: flex;
                    flex-direction: column;
                }
                .filters-section>div>ul>li>div.inactive-tooltip{
                    display: none;
                }
                    .filters-section>div>ul>li>div>button{
                        font-family: var(--fontfamily-regular-set);
                        width: 95%;
                        align-self: center;
                        color: black;
                        padding: .5em;
                    }
        

@media only screen and (min-width:601px){
    .filters-section>div{
        width: 10%;
        align-items: center;
        border-top-right-radius: 5em;
        border-bottom-right-radius: 5em;
        border-top-left-radius: 4em; /*Sacar este radio como constante de radio de los paneles con sombra*/
        transition-property: width;
        transition-duration: 500ms;
    }
        .filters-section>div>label{
            width: 1em;
            height: 1em;
            right: 0%;
            transform: rotate(180deg);
            margin-right: .5em;
            cursor: pointer;
        }
        .filters-section>div>span{
            font-size: .9em;
            left: 0%;
            margin-left: .8em;
        }
        .filters-section>div>ul{
            width: calc(100% - 2em);
            justify-content: left;
            font-size: .75em;
            transform: scale(0);
            opacity: 0;
            transition-property: opacity;
            transition-duration: 100ms;
            transition-property: transform;
            transition-duration: 60ms;
            transition-delay: 100ms;
        }
            .filters-section>input[type="checkbox"]:checked ~ div{
                width: 100%;
                border-top-right-radius: .8em;
                border-bottom-right-radius: 0em;
                border-top-left-radius: .8em;

            }
            .filters-section>input[type="checkbox"]:checked ~ div>ul{
                transform: scale(1);
                opacity: 1;
            }
            .filters-section>input[type="checkbox"]:checked ~ div>label{
                transform: rotate(0deg);
            }
            .filters-section>input[type="checkbox"]:checked ~ div>span{
                opacity: 0;
            }
        
                .filters-section>div>ul>li>div>button{
                    font-size: .85em;
                }
    
}
@media only screen and (min-width:768px) and (orientation:portrait){
    .filters-section{
        height: 2.6vh;
        min-height: 2.5em;
        max-height: 2.6vh;
    }
        /* .filters-section>div{
            padding-top: 1.5vh;
            padding-bottom: 1.5vh;
        } */
         
}
@media only screen and (max-width:600px){
    .filters-section>div{
        width: 100%;
        height: 10%;
        align-items: flex-start;
        border-top-right-radius: .8em;
        border-top-left-radius: .8em; /*Sacar este radio como constante de radio de los paneles con sombra*/
        position: absolute;
        transition-property: height;
        transition-duration: 500ms;
    }
            .filters-section>div>ul{
                font-size: 1em;
                width: 80%;
                height: 80%;
                overflow: auto;
                flex-direction: column;
                margin-top: 15%;
                border-top: .1em;
                border-color: var(--light-color-hex);
                border-style: solid;
                transform: scale(0);
                opacity: 0;
                transition-property: opacity;
                transition-duration: 100ms;
                transition-property: transform;
                transition-duration: 60ms;
                transition-delay: 100ms;
            }
                .filters-section>div>ul>li{
                    margin-top: 3%;
                    margin-bottom: 3%;
                    padding-top: 1%;
                    padding-bottom: 1%;
                    text-align: center;
                }
            .filters-section>div>label{
                top: 0%;
                right: 0%;
                transform: rotate(-90deg);
                margin-right: 1em;
                margin-top: 5%;
                margin-bottom: 5%;
            }
            .filters-section>div>span{
                font-size: 1em;
                letter-spacing: .15em;
                left: auto;
                top: 0%;
                margin-top: 5%;
                margin-bottom: 5%;
            }

            .filters-section>input[type="checkbox"]:checked ~ div{
                height:100%;
                border-bottom-right-radius: .8em;
                border-bottom-left-radius: .8em;
            }
            .filters-section>input[type="checkbox"]:checked ~ div>label{
                transform: rotate(90deg);
               
            }
            .filters-section>input[type="checkbox"]:checked ~ div>ul{
                transform: scale(1);
                opacity: 1;
            }
           
                    .filters-section>div>ul>li>div>button{
                        font-size: .8em;
                    }
}