.transactions-table{
    align-self: center;
    justify-self: center;
    width: 94%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

    .transactions-table>section{
        width: 100%;
        display: flex;
    }

.transactions-table-body-section{
    height: 80%;
}
.transactions-table-footer-section{
    height: 10%;
    justify-content: center;
    border-top: .1em;
    border-color: var(--light-color-hex);
    border-style: solid;
}
    .transactions-table-footer-section>div{
        width: 98%;
        height: 98%;
        display: flex;
    }
    .transactions-table-footer-section>div>button,.transactions-table-footer-section>div>a{
        color: var(--main-green-hex);
        font-family: var(--fontfamily-regular-set);
        display: flex;
        align-items: center;
    }
        .transactions-table-footer-section>div>button>span,.transactions-table-footer-section>div>a>span{
            display: inline-block;
            vertical-align: middle;
        } 
            .transactions-table-footer-section>div>button>span>img,.transactions-table-footer-section>div>a>span>img{
                width: 100%;
                height: 100%;
                transform: rotate(180deg);
            }
@media only screen and (min-width:601px) {
    .transactions-table-footer-section>div>button,.transactions-table-footer-section>div>a{
        font-size: .8em;
    }
        .transactions-table-footer-section>div>button>span,.transactions-table-footer-section>div>a>span{
            width: 1em;
            height: 1em;
        }
}
@media only screen and (min-width:768px) and (orientation:portrait){
    .transactions-table-footer-section{
        height: 4.6vh;
        min-height: 4.5em;
        max-height: 4.5em;
    }
        .transactions-table-body-section{
            height: calc(100% - 4.5em - 2.5em);
        }
}
@media only screen and (max-width:600px) {
.transactions-table-footer-section>div>button,.transactions-table-footer-section>div>a{
    font-size: .9em;
    margin-right: 2%;
}
    .transactions-table-footer-section>div>button>span,.transactions-table-footer-section>div>a>span{
        width: 1.3em;
        height: 1.3em;
    }
}