.clients-table{
    align-self: center;
    justify-self: center;
    width: 94%;
    height: 95%;
    display: flex;
    flex-direction: column;
}
    .clients-table>section{
        width: 100%;
        display: flex;
    }
   
    .clients-table-body-section{
        height: 80%;
    }
    .clients-table-footer-section{
        height: 10%;
        justify-content: center;
        border-top: .1em;
        border-color: var(--light-color-hex);
        border-style: solid;
    }
        .clients-table-footer-section>div{
            width: 98%;
            height: 98%;
            display: flex;
        }
        .clients-table-footer-section>div>button{
            color: var(--main-green-hex);
            font-family: var(--fontfamily-regular-set);
            
        }
            .clients-table-footer-section>div>button>span{
                display: inline-block;
                vertical-align: middle;
            } 
                .clients-table-footer-section>div>button>span>img{
                    width: 100%;
                    height: 100%;
                }
@media only screen and (min-width:601px) {
        
        .clients-table-footer-section>div>button{
            font-size: .8em;
        }
            .clients-table-footer-section>div>button>span{
                width: 1.5em;
                height: 1.5em;
            }
        
}
@media only screen and (min-width:768px) and (orientation:portrait){
    .clients-table-footer-section{
        height: 4.6vh;
        min-height: 4.5em;
        max-height: 4.5em;
    }
        .clients-table-body-section{
            height: calc(100% - 4.5em - 2.5em);
        }
}
@media only screen and (max-width:600px) {
    .clients-table-footer-section>div>button{
        font-size: .9em;
        margin-right: 2%;
    }
        .clients-table-footer-section>div>button>span{
            width: 1.3em;
            height: 1.3em;
        }
}
