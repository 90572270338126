@font-face {
    font-family: regular;
    font-display: swap;
    src: url(../fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
    font-family: medium;
    font-display: swap;
    src: url(../fonts/Roboto/Roboto-Medium.ttf);
}
@font-face {
    font-family: monserrat-regular;
    font-display: swap;
    src: url(../fonts/Montserrat/Montserrat-Regular.ttf);
}
:root{
    --light-color-hex:#CCCCCC;
    --dark-color-hex:#669999;
    --background-color-hex:white;

    --fontsize-clamp-901:clamp(12px,1.5vw,2.2vw);
    --fontsize-clamp-900:clamp(15px,1.5vw,2.2vw);

    --fontfamily-regular-set:regular,'sans-serif';
    --fontfamily-medium-set:medium,'sans-serif';

    --Inicia-gradient-color-horizontal:linear-gradient(90deg, rgba(1, 117, 156, 0.7) 0%, rgba(1, 192, 202, 0.7) 100%);
    --Inicia-gradient-color-vertical:linear-gradient(0deg, rgba(1, 117, 156, 0.7) 0%, rgba(1, 192, 202, 0.7) 100%);
     

    --container-indent-width:95%;
    --container-indent-height:98%;
}
div#modal>div{
    width: 100%;
    min-height: 100vh;
    position: fixed;
    background: var(--Inicia-gradient-color-horizontal);
    background-attachment: fixed;
    display: none;
    z-index: 5;
    top: 0%;
    left: 0%;
    /* opacity: .7; */
}
@keyframes rotate {
    100% {
    transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    }

    50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
    }

    100% {
    stroke-dashoffset: -125px;
    }
}

/* Modal form */
    .modal-form{
        margin: auto;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 0.8em;
        position: relative;
    }
        .modal-form-tittle{
            background-color: #00ade5;
            width: fit-content;
            height: fit-content;
            padding: 0.8em;
            border-top-right-radius: 5em;
            border-top-left-radius: 2.8em;
            border-bottom-right-radius: 5em;
            color: white;
            font-family: var(--fontfamily-regular-set);
        }
        .modal-form-close{
            position: absolute;
            top: 0%;
            right: 0%;
            background-image: url(../images/inicia/circlex-hollow-orange.svg);
            background-size:contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .modal-form-actions{
            align-self: center;
            width: 95%;
            height: fit-content;
            display: flex;
            justify-content: flex-end;
        }
            .modal-form-actions>button{
                color: white;
                font-family: var(--fontfamily-regular-set);
                border-radius: 5em;
                padding: .4em 1.3em;
                margin-left: .5em;
                margin-right: .5em;
            }
/* Modal form */

/* Modal alert */
.modal-alert{
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.8em;
    position: relative;
}
    .modal-alert-tittle{
        width: fit-content;
        height: fit-content;
        align-self: center;
        color: #E98819;
        font-family: var(--fontfamily-medium-set);
    }
    .modal-alert-message{
        font-family: var(--fontfamily-regular-set);
        align-self: center;
    }
    .modal-alert-close{
        position: absolute;
        top: 0%;
        right: 0%;
        background-image: url(../images/inicia/circlex-hollow-orange.svg);
        background-size:contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .modal-alert-actions{
        align-self: center;
        width: 90%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .modal-alert-actions>button{
            width: fit-content;
            height: fit-content;
            color: white;
            font-family: var(--fontfamily-regular-set);
            border-radius: 5em;
            padding: .4em 1.3em;
            margin-left: .5em;
            margin-right: .5em;
        }
/* Modal alert */

/* Add client Form on Modal*/
    .modal-form>.form-addclient{
        align-self: center;
        width: 95%;
        height: 70%;
        margin-top: 2%;
        margin-bottom: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .modal-form>.form-addclient>span{
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        position: relative;
    }
        .modal-form>.form-addclient>span>input{
            font-family: var(--fontfamily-light-set);
            margin-left: .1em;
            margin-right: .1em;
            padding: .3em .2em;
            text-indent: .4em;
            background-color:rgba(96, 210, 202, .2);
            border-radius: .5em;
            
        }
        .modal-form>.form-addclient>span>img{
            height: 1em;
            width: 1em;
            position: absolute;
            right: 2%;
        }
        .modal-form>.form-addclient>span>label{
            font-family: var(--fontfamily-regular-set);
            
        }

/* Add client Form on Modal*/
@media only screen and (min-width:601px){
    .modal-form{
        width: 50%;
        height: 65vh;
    }
   
        .modal-form-close,.modal-alert-close{
            width: .85em;
            height: .85em;
            margin: .5em;
            cursor: pointer;
        }
        .modal-form-tittle{
            font-size: .85em;
        }
            .modal-form>.form-addclient>span{
                font-size: .8em;
                margin: .3em;
            }
                .modal-form>.form-addclient>span>input{
                    width: 75%;
                }
                .modal-form>.form-addclient>span>label{
                    width: 25%;
                    min-width: fit-content;
                }
        .modal-form-actions{
            margin-top: 0%;
            margin-bottom: 4%;
        }
            .modal-form-actions>button{
                font-size: .8em;
                cursor: pointer;
            }
        
        .modal-alert{
            width: 25%;
            height: fit-content;
        }
            .modal-alert-close{
                width: .85em;
                height: .85em;
                margin: .5em;
                cursor: pointer;
            }
            .modal-alert-tittle{
                font-size: 1em;
                margin-top: 0.6em;
                margin-bottom: 0.6em;
            }
            .modal-alert-message{
                font-size: .75em;
                margin-top: .8em;
                margin-bottom: .8em;
                width: 90%;
            }

            .modal-alert-actions{
                margin-top: .3em;
                margin-bottom: .3em;
            }
                .modal-alert-actions>button{
                    font-size: .8em;
                    margin-top: .3em;
                    margin-bottom: .3em;
                    padding-left: 3.3em;
                    padding-right: 3.3em;
                    cursor: pointer;
                }
}
@media only screen and (min-width:768px) and (orientation:portrait){
    .modal-form {
        width: 50%;
        height: fit-content;
    }
        .modal-form-close, .modal-alert-close{
            width: .95em;
            height: .95em;
        }
        .modal-form>.form-addclient{
            margin-top: 4%;
            margin-bottom: 4%;
        }
            .modal-form>.form-addclient>span{
                margin: .6em;
            }
        .modal-form-actions{
            margin-top: 4%;
            margin-bottom:4% ;
        }
        .modal-alert-tittle{
            font-size: 1.2em;
            margin-top: 0.6em;
            margin-bottom: 0.6em;
        }
        .modal-alert-message{
            font-size: .9em;
            margin-top: 0.8em;
            margin-bottom: 0.8em;
            width: 90%;
            line-height: 1.5;
        }
        .modal-alert-actions {
            margin-top: 0.4em;
            margin-bottom: 0.4em;
        }
            .modal-alert-actions>button{
                margin-top: 0.5em;
                margin-bottom: 0.5em;
                font-size: .9em;
            }
}
@media only screen and (max-width:600px){

    .modal-form{
        width: 90%;
        height: 55vh;
        min-height: fit-content;
    }
        .modal-form-close{
            width: 1.1em;
            height: 1.1em;
            margin: .5em;
        }
        .modal-form-tittle{
            font-size: .9em;
        }
            .modal-form>.form-addclient>span{
                font-size: .85em;
                margin-top: .2em;
                margin-bottom: .2em;
                justify-content: space-between;
            }
                .modal-form>.form-addclient>span>input{
                    width: 48%;
                }
                .modal-form>.form-addclient>span>label{
                    width: 48%;
                    min-width: fit-content;
                }
        .modal-form-actions{
            margin-top: 4%;
            margin-bottom: 4%;
        }
            .modal-form-actions>button{
                font-size: .9em;
            }

    .modal-alert{
        width:90%;
        height: fit-content;
    }
        .modal-alert-close{
            width: 1.1em;
            height: 1.1em;
            margin: .5em;
        }
        .modal-alert-tittle{
            font-size: 1em;
            margin-top: 0.6em;
            margin-bottom: 0.6em;
        }
        .modal-alert-message{
            font-size: .75em;
            margin-top: .8em;
            margin-bottom: .8em;
            width: 90%;
        }

        .modal-alert-actions{
            margin-top: .3em;
            margin-bottom: .3em;
        }
            .modal-alert-actions>button{
                font-size: .8em;
                margin-top: .3em;
                margin-bottom: .3em;
                padding-left: 2.3em;
                padding-right: 2.3em;
            }
}
/* Add client Form on Modal*/
