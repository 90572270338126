* {
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    vertical-align: baseline;
    outline: none;
  }
  /* Evitamos problemas con las imagenes */
  img, picture, video, iframe, figure {
    max-width: 100%;
    width: 100%;
    display: block;
  }
  /* Reseteamos los enlaces para funcionar como cajas... */
  a {
    display: block;
    text-decoration: none;
    color: inherit;
    font-size: inherit;
  }
  
  /* ... excepto los que se encuentran en párrafos */
  p a {
    display: inline;
  }
  
  /* Quitamos los puntos de los <li> */
  li {
    list-style-type: none;
  }
  
  /* Configuramos anclas suaves */
  html {
    scroll-behavior: smooth;
  }
  
  /* Desactivamos estilos por defecto de las principales etiquetas de texto */
  h1, h2, h3, h4, h5, h6, p, span, a, strong, blockquote, i, b, u, em {
    font-size: 1em;
    font-weight: inherit;
    font-style: inherit;
    text-decoration: none;
    color: inherit;
  }
  
  /* Evitamos problemas con los pseudoelementos de quotes */
  blockquote:before, blockquote:after, q:before, q:after {
    content: "";
    content: none;
  }
  
  /* Nivelamos problemas de tipografías y colocación de formularios */
  form, input, textarea, select, button, label {
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
    color: inherit;
    display: block;
  }
  
  /* Reseteamos las tablas */
  table, tr, td {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  svg {
    width: 100%;
    display: block;
    /* fill: currentColor; */
  }
  html{
    min-height: 100%;
  }
  body {
    min-height: 100vh;
    font-size: 100%;
  }