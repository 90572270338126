/* Scroll bar */
.products-table{
    align-self: center;
    justify-self: center;
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
}
    .products-table>section{
        width: 100%;
        display: flex;
    }
        .products-table-filters-section>button{
            background-color: var(--main-green-hex);
            padding-top: 2%;
            padding-bottom: 2%;
            padding: .5em;
            color: white;
            border-top-right-radius: 5em;
            border-bottom-right-radius: 5em;
        }
    .products-table-body-section{
        height: 80%;
        overflow: scroll;
    }
        .products-table-body-section>table{
            width: 100%;
            height: inherit;
            font-size: .9em;
        }
        .products-table-body-section>table tr{
            height: fit-content;
        }
        .products-table-body-section>table th{
            height: fit-content;
            font-family: var(--fontfamily-medium-set);
        }
        .products-table-body-section>table td{
            height: fit-content;
            font-family: var(--fontfamily-regular-set);
        }
        .products-table-actions-section{
            justify-content:space-between;
        }
